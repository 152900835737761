#main{
    padding: ($content-padding * 2) $content-padding;
    background-color: $white;
    color: rgb(0, 0, 0);

    .feature-sur-mesure{
        .row{
            margin-top: 5rem;
            margin-bottom: 5rem;
            .col{        
                box-shadow: $box-shadow;
                border: 1px solid white;
                padding: 5rem 2rem !important;
                background-color: $primary;
                color: white;
                i{
                    display: block;
                    margin: 1rem auto;
                    width: 60px;
                    text-align: center;
                }
                h3{
                    font-size: 2.5rem;
                    text-align: center;
                }
                p{
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
        }
    }

    .row-content-text{
        .col-text{
            .row > div:first-child{
                background-color: $secondary;
                .card{
                    background-color: $secondary;
                    border: 0;
                    padding-right: 5rem; 
                }
            }
            .row > div:last-child{
                top: 25px;
                right: 0;
                background-color: $primary;
                padding: 3rem;
                .card{
                    border: 0;
                    .card-body{
                        background-color: $primary;
                        color: white;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .row .col-left-side{
                    position: absolute;
                }
            }
        }
    }

    .row-news{
        min-height: 600px;
        .col:last-child{
            h5{
                background-color: $secondary;
            }
        }
        .col{
            min-height: 600px;
        }
    }

    .profile{
        margin-top: 5rem;;
        color: black;
        h2{
            color: black;
            font-size: 3rem;
            @include media-breakpoint-only(xs) {
                font-size: 2rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 2rem;
            }
        }
        .imgs{
            a{
                cursor: pointer;
                img{
                    border: 1px solid $white;
                    box-shadow: $box-shadow;
                }
            }
            
        }
        .card{
            background: none;
            border: 0;
            .card-body{
                .card-title{
                    padding-bottom: 0.5rem;
                    color: black;
                    font-size: 1.75rem;
                }
                p{
                    margin-bottom: 0;
                    color: black;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.off-line{
    #main{
        margin: 0;
        padding: 0;
        .overflow-hidden{
            height: 650px;
            img{
                margin-top: -250px;;
            }
        }
        .container{
            h1{
                margin-top: 2rem;
            }
            p{
                font-size: 1.5rem;
                .tel{
                    background-color: $secondary;
                    color: white;
                    width: 300px;
                }
            }
        }
    }
}

.accueil{
    #main{
        padding: ($content-padding * 5) $content-padding;
        h1{
            text-align: center;
        }
    }
}