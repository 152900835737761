.block-slider{
    //padding: 5rem 0;
    h2{
        font-family: $fontHeading;
        font-weight: 600;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 2rem;
    }
    .tns-ovh{
        .tns-inner{
            overflow: hidden;
            .slider{
                margin: 0;
                .item{
                    .card{
                        background: none;
                        border: 0;
                        .card-body{
                            a{
                                box-shadow: $box-shadow;
                                border: $border-white;
                                display: block;
                                cursor: pointer;
                            }
                            a.stretched-link{
                                box-shadow: inherit;
                                border: inherit;
                                display: inherit;
                            }
                            .no-modal{
                                box-shadow: $box-shadow;
                                border: $border-white;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}