.block-gallery{
    //padding: 3rem 0 5rem 0;
    .col{
        .card{
            box-shadow: $box-shadow;
            a{
                cursor: pointer;
            }
        }
    }

    .col.hover-zoom-left, .col.hover-zoom-right{
        overflow: hidden;
        img{
            transform: scale(1) rotate(0);
            filter: grayscale(0);
            transition: all 1s;
        }
    }

    .col.hover-zoom-left:hover{
        cursor: pointer;
        img{
            transform: scale(1.2) rotate(-2deg);
            filter: grayscale(90%);
            transition: all 1s;
        }
    }

    .col.hover-zoom-right:hover{
        cursor: pointer;
        img{
            transform: scale(1.2) rotate(2deg);
            filter: sepia(60%);
            transition: all 1s;
        }
    }
}