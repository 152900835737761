#feature{
    padding: ($content-padding * 2) $content-padding;
    background-color:$secondary;
    color: black;

    .feature-accueil{
        h3{
            min-height: 2rem;
        }
    }

    .card-accueil-services{
        padding: 3rem;
        h3{
            font-family: "Play", sans-serif;
            color: black;
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
        @include media-breakpoint-only(xs) {
            padding: 0;
        }
        @include media-breakpoint-only(sm) {
            padding: 0;
        }
        .row{
            .col{
                .card{
                    .card-body{
                        i{
                            color: $secondary;
                        }
                        h5{
                            background-color: $primary;
                        }
                        .card-text{
                            p{
                                color: black;
                            }
                        }
                    }
                }
            }
        }
    }

    .card-sercices{
        h3{
            font-size: 2.5rem !important;
            color: white;
            margin-top: 3rem;
        }
        .row{
            .card{
                box-shadow: $box-shadow;
                .card-body{
                    .card-title{
                        padding: 0.75rem;
                        background-color: $primary;
                        text-align: center;
                        color: white;
                    }
                }
            }
        }
    }

    .card-horizontal-accueil{
        margin-bottom: 4rem;
        h3{
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 2rem;
            color: white;
            font-size: 2.5rem;
        }
        .card{
            border: $border-white;
            margin-bottom: 2rem;
            .row{
                .col{
                    .card-body{
                        .card-title{
                            text-align: center;
                            i{
                                display: block;
                                margin: 1rem 0;
                                color: $secondary;
                            }
                        }
                    } 
                }
    
                .col.hover-zoom{
                    overflow: hidden;
                    img{
                        transform: scale(1) rotate(0);
                        filter: grayscale(0);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(-2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
    
                .col.hover-zoom.rotate-left:hover{
                    cursor: pointer;
                    img{
                        transform: scale(1.2) rotate(2deg);
                        filter: grayscale(90%);
                        transition: all 1s;
                    }
                }
            }
        }
    }

    .content-info{
        margin: 5rem;
        border-bottom: 1px solid $white;
        .col{
            text-align: center;
            i{
                display: block;
                margin: 1rem 0 ;
                color: $white;
                font-size: 3rem;
            }
            p{
                font-size: 1.3rem;
            }
        }
    }

    .profile-situation{
        color: black;
        h2{
            color: white;
        }
        .imgs{
            img{
                border: 1px solid $white;
                box-shadow: $box-shadow;
            }
        }
        .card-body{
            .card-title{
                padding: 0.5rem;
                background: $secondary;
            }
            p{
                margin-bottom: 0.5rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                
            }
        }
        
    }

    .profile{
        margin-top: 5rem;;
        color: black;
        h2{
            color: black;
            font-size: 3rem;
            @include media-breakpoint-only(xs) {
                font-size: 2rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 2rem;
            }
        }
        .imgs{
            a{
                cursor: pointer;
                img{
                    border: 1px solid $white;
                    box-shadow: $box-shadow;
                }
            }
            
        }
        .card{
            background: none;
            border: 0;
            .card-body{
                .card-title{
                    padding-bottom: 0.5rem;
                    color: black;
                    font-size: 1.75rem;
                }
                p{
                    margin-bottom: 0;
                    color: black;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .slider-accueil{
        padding: 5rem 0;
        h2{
            font-family: $fontHeading;
            font-weight: 600;
            font-size: 2rem;
            text-align: center;
            margin-bottom: 2rem;
        }
        .tns-ovh{
            .tns-inner{
                margin: 0;
                .slider{
                    .item{
                        .card{
                            background: none;
                            border: 0;
                            .card-body{
                                a{
                                    box-shadow: $box-shadow;
                                    border: $border-white;
                                    display: block;
                                    cursor: pointer;
                                }
                                .no-modal{
                                    box-shadow: $box-shadow;
                                    border: $border-white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}