#topUtility{
    padding: ($content-padding * 2) $content-padding;
    background-color: $primary;
    color: black;

    a{
        color: white !important;
    }

    .card-tarifs-fournitures{
        .row{
            .col{
                .card{
                    .card-body{
                        i{
                            color: black;
                        }
                        h5{
                            font-size: 2.5rem;
                            background-color: $yellow-gray;
                            color: black;
                        }
                        .card-text{
                            padding: 0.75rem;
                            p{
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .carousel-news{
        height: 400px;
        .carousel-inner{
            height: 400px;
        }
    }
}