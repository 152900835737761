#subFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $primary;
    color: white;

    .content-info{
        box-shadow: $box-shadow;
        border: 1px solid white;
        margin: 5rem 3rem;
        padding: 3rem 2rem;
        background-color: $secondary;
        .col-title{
            h3{
                i{
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 4rem;
                }
                font-size: 2rem;
            }
        }
        .col-text{
            p{
                font-size: 4rem;
            }
        }
    }

    .feature-services{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h2{
            text-align: center;
            font-size: 3rem;
        }
        .list-services{
            li{
                background: inherit;
                color: white;
                border: 0;
                border-bottom: 1px solid rgba($color: white, $alpha: 0.35);
                font-size: 1.2rem;
            }
        }
    }

    .titre-restauration{
        .col{
            p{
                font-size: 1.2rem;
                text-align: left;
                .tel{
                    font-size: 2.5rem;
                    font-weight: bold;
                }
                .bi{
                    color: $green;
                    font-size: 1.5rem;
                    margin-right: 0.5rem;
                }
            }
        }
        .col p:nth-child(2){
            margin-top: 2rem;
        }
    }

    .titre-reservation{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h3{
            min-height: 2rem;
            font-size: 3rem;
            @include media-breakpoint-only(xs) {
                font-size: 1.5rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 1.5rem;
            }
            i{
                display: block;
                margin-bottom: 1rem;
                font-size: 4rem;
                color: $yellow-gray;
            }
        }
        p{
            font-size: 2rem;
            @include media-breakpoint-only(xs) {
                font-size: 2rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 2rem;
            }
            margin-top: 2rem;

            a{
                color: $white;
                font-size: 2rem;
                background-color: $yellow-gray;
            }
        }
    }
}