#topFeature{
    padding: ($content-padding * 2) $content-padding;
    background-color: $gray-500;
    color: black;

    .titre-reservation{
        margin-top: 3rem;
        margin-bottom: 3rem;
        h3{
            min-height: 2rem;
            font-size: 3rem;
            @include media-breakpoint-only(xs) {
                font-size: 1.5rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 1.5rem;
            }
            i{
                display: block;
                margin-bottom: 1rem;
                font-size: 4rem;
                color: $primary;
            }
        }
        p{
            font-size: 2rem;
            @include media-breakpoint-only(xs) {
                font-size: 2rem;
            }
            @include media-breakpoint-only(sm) {
                font-size: 2rem;
            }
            margin-top: 2rem;
            .tel{
                color: black;
            }

            a{
                color: $black;
                font-size: 2rem;
            }
        }
    }

    .gallery-chalet{
        padding: 3rem 0 5rem 0;
    }

}